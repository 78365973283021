import { isInIframe } from '../utils/iframe'

export default defineNuxtRouteMiddleware(to => {
  if (import.meta.server) {
    return
  }

  if (isInIframe()) {
    return
  }

  if (/^\/internal-job-referrals\/?$/.test(to.fullPath)) {
    return navigateTo('/careers/jobs')
  }

  if (/^\/internal-job-referrals\//.test(to.fullPath)) {
    return navigateTo(to.fullPath.replace('internal-job-referrals', 'jobs'))
  }
})
